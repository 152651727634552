import React, { ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import { errorFilled } from "@teliads/icons";

import "./b2x-telia-field-error.scss";

type Props = {
  children: ReactNode;
  className?: string;
  dataTestid?: string;
  relevant?: "additions" | "all" | "removals" | "text" | "additions text";
};
export const TeliaFieldError = ({
  children,
  className,
  dataTestid,
  relevant = "additions text",
}: Props) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-field-error", "components-fielderrortext--docs");
  }, []);

  const classname = className ? `telia-field-error ${className}` : "telia-field-error";
  return (
    <div
      className={classname}
      aria-live="assertive"
      role="alert"
      aria-relevant={relevant}
      data-testid={dataTestid}
    >
      <TeliaIcon size="md" className="telia-field-error-icon" svg={errorFilled.svg} />
      {children}
    </div>
  );
};
